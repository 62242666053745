import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { rolesParaInspeccion } from './permisosMenusRutas';



// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'redirect' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'redirect' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') },
            { path: 'confirmacion-email', loadChildren: () => import('app/modules/auth/confirm-email/confirm-email.routes') }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') },
            { path: 'redirect', loadChildren: () => import('app/shared/redirect/redirect.routes') },
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'usuario-dashboard', data: {
                permissions: {
                    only: ['ROLE_ADMIN','ROLE_USER'],
                    redirectTo: '/redirect'
                }
            }, loadChildren: () => import('app/modules/usuario/dashboard/usuario-dashboard.routes') },
            { path: 'alta', data: {
                permissions: {
                    only: ['ROLE_ADMIN','ROLE_USER'],
                    redirectTo: '/redirect'
                }
            }, loadChildren: () => import('app/modules/admin/alta/alta.routes') },
            { path: 'solicitudes', loadChildren: () => import('app/modules/admin/solicitudes/solicitudes.routes') },
            { path: 'revisor', loadChildren: () => import('app/modules/admin/revisor/revisor.routes') },
            { path: 'dashrevisor', loadChildren: () => import('app/modules/admin/dashrevisor/dashrevisor.routes') },
            {
                path: 'dashinspector', canActivate: [NgxPermissionsGuard], data: {
                    permissions: {
                        only: [...rolesParaInspeccion],
                        redirectTo: '/redirect'
                    }
                }, loadChildren: () => import('app/modules/inspector/dashinspector/dashinspector.routes')
            },
            {
                path: 'inspector-solicitud', canActivate: [NgxPermissionsGuard], data: {
                    permissions: {
                        only: [...rolesParaInspeccion],
                        redirectTo: '/redirect'
                    }
                }, loadChildren: () => import('app/modules/inspector/inspector-solicitud/inspector-solicitud.routes')
            },
            { path: 'pago', loadChildren: () => import('app/modules/admin/pago/pago.routes') },
            { path: 'solicitud-alta-empresa', canActivate: [NgxPermissionsGuard], data: {
                permissions: {
                    only: ['ROLE_ADMIN','ROLE_USER'],
                    redirectTo: '/redirect'
                }
            }, loadChildren: () => import('app/modules/usuario/solicitud-alta-empresa/solicitud-alta-empresa.routes') },
            { path: 'usuario-pagos', loadChildren: () => import('app/modules/usuario/pagos/usuario-pagos.routes') },
            { path: 'usuario-dictamenes', loadChildren: () => import('app/modules/usuario/dictamenes/usuario-dictamenes.routes') },
            {
                path: 'usuarios',
                canActivate: [NgxPermissionsGuard], data: {
                    permissions: {
                        only: ['ROLE_ADMIN'],
                        redirectTo: '/redirect'
                    }
                }, loadChildren: () => import('app/entities/user-management/users.module').then(m => m.UserManagementModule)
            }
        ]
    },
    { path: '**', loadChildren: () => import('app/modules/error/error-404/error-404.module').then(m => m.Error404Module) }
];



