import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private data: { [key: string]: any } = {};
  private checksum: string = '';
  private claveSecreta: string = '$DARE2024'
  private dataSubject = new Subject<any>();
  oData$ = this.dataSubject.asObservable();

  constructor() {
    // Recuperar datos y checksum almacenados en localStorage al inicializar el servicio
    const storedData = localStorage.getItem('dataSDARE');
    const storedChecksum = localStorage.getItem('checksumSDARE');
    if (storedData && storedChecksum) {
      this.data = JSON.parse(storedData);
      this.checksum = storedChecksum;
    }
  }

  // Agregar o actualizar una variable en el servicio y actualizar el localStorage
  setVariable(key: string, value: any) {
    this.data[key] = value;
    this.updateLocalStorage();
  }

  // Eliminar una variable del servicio y actualizar el localStorage
  deleteVariable(key: string) {
    delete this.data[key];
    this.updateLocalStorage();
  }

  // Obtener una variable del servicio
  getVariable(key: string): any {
    return this.data[key];
  }

  // Limpiar el localStorage y restablecer el servicio
  clearLocalStorage() {
    localStorage.removeItem('dataSDARE');
    localStorage.removeItem('checksumSDARE');
    this.data = {};
    this.checksum = '';
  }

  // Actualizar el localStorage con los datos y el checksum actualizados
  private updateLocalStorage() {
    const jsonData = JSON.stringify(this.data);
    this.checksum = CryptoJS.HmacSHA256(jsonData, this.claveSecreta).toString();
    localStorage.setItem('dataSDARE', jsonData);
    localStorage.setItem('checksumSDARE', this.checksum);
  }

  // Verificar la integridad de los datos al inicio o cuando se acceda a ellos
  verifyDataIntegrity(): boolean {
    const computedChecksum = CryptoJS.HmacSHA256(JSON.stringify(this.data), this.claveSecreta).toString();
    return this.checksum === computedChecksum;
  }

  updateData(data: any) {
    this.dataSubject.next(data);
  }
}