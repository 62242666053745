/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { rolesParaInspeccion } from 'app/permisosMenusRutas';

export const defaultNavigation: FuseNavigationItem[] = [

];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'usuario-dashboard',
        title: 'Mis trámites',
        type: 'basic',
        icon: 'heroicons_outline:document',
        link: '/usuario-dashboard',
        permissions: ["ROLE_ADMIN", "ROLE_USER"]
    },
    {
        id: 'usuario-pagos',
        title: 'Mis pagos',
        type: 'basic',
        icon: 'heroicons_outline:credit-card',
        link: '/usuario-pagos',
        permissions: ["ROLE_ADMIN", "ROLE_USER"]
    },
    {
        id: 'usuario-dictamenes',
        title: 'Mis dictamenes',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/usuario-dictamenes',
        permissions: ["ROLE_ADMIN", "ROLE_USER"]
    },
    {
        id: 'new-link',
        title: 'Vista Revisor',
        type: 'basic',
        icon: 'heroicons_outline:document',
        link: '/dashrevisor',
        permissions: ["ROLE_ADMIN", "ROLE_REVISOR_FOMENTO","ROLE_JEFE_DEPARTAMENTO"]
    },
    {
        id: 'new-link',
        title: 'Otros elementos',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/solicitudes',
        permissions: ["ROLE_ADMIN"]
    },
    {
        id: 'inspector',
        title: 'Inspector',
        type: 'basic',
        icon: 'heroicons_outline:magnifying-glass-circle',
        link: '/dashinspector',
        permissions: [...rolesParaInspeccion]
    }

];

export const futuristicNavigation: FuseNavigationItem[] = [
   
];

export const horizontalNavigation: FuseNavigationItem[] = [
   
];
