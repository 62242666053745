export const environment = {
    production: false,
    apiUrl: 'https://test-api-sdare.municipiodurango.gob.mx',
    googleKey:'AIzaSyD16vSmWtrM0fWusir7Q3AkAp1ehgjswOo',
    linkCorreos:'https://www.correosdemexico.gob.mx/SSLServicios/ConsultaCP/Descarga.aspx',
    mitCompany: '0526',
    mitIdBranch: '0078',
    mitUser: '0526SIUS17',
    mitPwd: 'ADMINDURAN10',
    mercureUrl: 'https://test-mercure-sdare.municipiodurango.gob.mx',
    mercureToken: 'eyJhbGciOiJIUzI1NiJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOlsiKiJdLCJzdWJzY3JpYmUiOlsiaHR0cHM6Ly9leGFtcGxlLmNvbS9teS1wcml2YXRlLXRvcGljIiwie3NjaGVtZX06Ly97K2hvc3R9L2RlbW8vYm9va3Mve2lkfS5qc29ubGQiLCIvLndlbGwta25vd24vbWVyY3VyZS9zdWJzY3JpcHRpb25zey90b3BpY317L3N1YnNjcmliZXJ9Il0sInBheWxvYWQiOnsidXNlciI6Imh0dHBzOi8vZXhhbXBsZS5jb20vdXNlcnMvZHVuZ2xhcyIsInJlbW90ZUFkZHIiOiIxMjcuMC4wLjEifX19.nz5dZiXmUdqBYuVGWJO4cevTmAWjRUCRZE4hbZcydjo'
};
